import { storyblokEditable } from "gatsby-source-storyblok"
import React from "react"

import CardMachineCard from "../../../../V2/Cards/Product/CardMachine"

import { usePromotion } from "@hooks/products"
import {
  useGetEcoSystemObjectFields,
  useGetPurchasableItem,
} from "@hooks/V2/storyblok"
import { getUrlFromStoryblokLink, linkIsNotNull } from "@utils/storyblok"

const StoryblokCardMachineCard = ({
  blok,
}: Storyblok.BlokProps<Storyblok.CardMachineCard>) => {
  const getEcoSystemObjectFields = useGetEcoSystemObjectFields()
  const getPurchasableItem = useGetPurchasableItem()

  const ecoSystemFields = getEcoSystemObjectFields(blok?.productConfig)
  const purchasableItem = getPurchasableItem(
    blok?.productConfig.uuid,
    blok?.productConfig.content
  )

  const { renderPromotion } = usePromotion(
    blok.productConfig.content.promotionStartDate,
    blok.productConfig.content.promotionEndDate
  )

  return (
    <CardMachineCard
      productName={blok.productConfig.content.name}
      productDescription={
        blok.customDescription ||
        blok.productConfig.content.shortDescription ||
        ""
      }
      imageUrl={blok.productConfig.content.image.filename}
      imageAlt={blok.productConfig.content.image.alt}
      priceAdornmentText="R"
      priceValue={ecoSystemFields?.price}
      priceSuperscriptText={
        ecoSystemFields.originalPrice && renderPromotion
          ? `Was R${ecoSystemFields.originalPrice}`
          : undefined
      }
      priceStrikeSuperscriptText={
        ecoSystemFields?.originalPrice && renderPromotion ? true : false
      }
      priceSubscriptText={ecoSystemFields.priceSubScript}
      priceSideScriptText={ecoSystemFields.priceSideScript}
      ctaText={ecoSystemFields.ctaText || "Buy Now"}
      ctaLink={
        linkIsNotNull(ecoSystemFields.ctaLink)
          ? ecoSystemFields.ctaLink
          : undefined
      }
      learnMoreButtonUrl={
        blok.showLearnMore && ecoSystemFields.detailsLink
          ? getUrlFromStoryblokLink(ecoSystemFields.detailsLink)
          : undefined
      }
      calloutLabel={blok.verticalLabel}
      purchasableItem={purchasableItem}
      badgeText={blok.productConfig.content.badges?.[0]?.text}
      badgeAccentColor={blok.productConfig.content.badges?.[0]?.color}
      isPurchasable={blok.isPurchasable}
      reasonNotAvailable={ecoSystemFields.reasonNotAvailable}
      waitlistLink={ecoSystemFields.waitlistLink}
      // @ts-ignore to have more strict typing compared to Storybloks generic object typing
      {...storyblokEditable(blok)}
    />
  )
}

export default StoryblokCardMachineCard
